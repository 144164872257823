<!--  -->
<template>
  <div class="production">
    <van-nav-bar
        title="所有任务"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />
    <div class="production_tabs">
      <div class="production_tab" v-for="item in tabs_item" :key="item.type">
        <div
            @click="changeHeadleItem(item)"
            class="production_tab_item"
            :class="[item.active == true ? 'active' : ' ']"
        >
          {{ item.item }}
        </div>
      </div>
      <div class="production_all">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item
              @change="dropdownChange"
              v-model="value"
              :options="choice_item"
          />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="search">
      <van-search
          @search="onSearch"
          v-model="searchKey"
          placeholder="请输入搜索关键字"
      />
    </div>
    <scroll class="production_scroll" :onLoadMore="onLoadMore">
      <div
          class="production_child_item"
          v-for="(item, index) in taskInfo"
          :key="index"
      >
        <div class="production_child_item_header">
          <div class="production_child_item_header_l">
            <div class="model">{{ item.woCode }}</div>
            <div class="line"></div>
            <div class="name">{{ item.wpName }}</div>
          </div>
          <div class="state">
            <span v-if="item.state == 0" class="not_started">未开始</span>
            <span v-else-if="item.state == 1">执行中</span>
            <span v-else-if="item.state == 2" class="start_end">已结束</span>
            <span v-else>全部</span>
          </div>
        </div>
        <div class="production_child_item_name">
          产品名称：<span>{{ item.productName }}</span>
        </div>
        <div class="production_child_item_schedule">
          {{ item.goodProductCounts == null ? "0" : item.goodProductCounts }}/{{
            item.planCounts
          }}
        </div>
        <div class="horizontal_line"></div>
        <div class="production_child_item_distribution" v-if="item.state == 2">
          <div
              class="distribution"
              @click="adjustment(item)"
          >
            <div class="distribution_item">分配</div>
          </div>
        </div>
        <div class="production_tiao" v-if="item.state == 1">
          <div
              class="production_tiao_l"
          >
            <div>
              分配列表:<span>{{ item.worker }}</span>
            </div>
          </div>
          <div
              class="production_tiao_r"
              @click="adjustment(item)"
          >
            <div class="distribution_item">调整</div>
          </div>
        </div>
      </div>
    </scroll>
    <van-popup
        v-model="adjustmentShow"
        position="bottom"
        round
        :close-on-click-overlay="false"
        :style="{ height: '30%' }"
        class="jurisdiction"
    >
      <div class="jurisdiction_headle">
        <div @click="adjustmentShow = false">取消</div>
        <div @click="headleItemAdjustment">确定</div>
      </div>
      <van-checkbox-group v-model="adjustmentList">
        <van-checkbox
            :name="item.nickname"
            v-for="item in defectiveItemObjList"
            :key="item.id"
        >{{ item.nickname }}
        </van-checkbox
        >
      </van-checkbox-group>
    </van-popup>
  </div>
</template>

<script>
import {getSysUserList, getTaskInfo} from "@/api/work";
import scroll from "@/components/scroll";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["departmentCode", "userInfo"]),
  },
  components: {
    scroll,
  },
  data() {
    //这里存放数据
    return {
      tabs_item: [
        {
          item: "我的",
          type: 0,
          active: true,
        },
        {
          item: "未分配",
          type: 1,
          active: false,
        },
        {
          item: "已分配",
          type: 2,
          active: false,
        },
      ],
      choice_item: [
        {
          text: "全部",
          value: 0,
        },
        {
          text: "未开始",
          value: 1,
        },
        {
          text: "执行中",
          value: 2,
        },
        {
          text: "已结束",
          value: 3,
        },
      ],
      value: 0,
      searchKey: "",
      pageIndex: 1,
      pageSize: 1000,
      defectiveItemObjList: [],
      adjustmentList: [],
      taskInfo: [],
      adjustmentShow: false,
    };
  },
  //监控data中的数据变化
  //方法集合
  methods: {
    onClickLeft() {
      this.$router.push("/task");
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    adjustment(i) {
      console.log(i)
      this.adjustmentShow = true
    },
    headleItemAdjustment() {

    },
    changeHeadleItem(i) {
      var _that = this;
      _that.loading = false;
      _that.pageIndex = 1;
      _that.taskInfo = [];
      _that.tabs_item.forEach((v) => {
        v.active = false;
        if (v.type == i.type) {
          v.active = true;
          _that.onLoad();
        }
      });
    },
    dropdownChange(value) {
      this.value = value;
      this.onLoad();
    },
    onSearch(e) {
      this.searchKey = e
      this.onLoad()
    },
    async getUserInfo() {
      const res = await getSysUserList({
        departmentCode: this["departmentCode"],
        labelCode: "generate_person",
      });
      this.defectiveItemObjList = res;
    },
    async onLoad() {
      try {
        const index = this.tabs_item.findIndex((v) => v.active);
        let state = "";
        if (this.value == 0) {
          state = "";
        } else if (this.value == 1) {
          state = 0;
        } else if (this.value == 2) {
          state = 1;
        } else if (this.value == 3) {
          state = 2;
        }
        const params = {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          state,
          searchKey: this.searchKey,
          type: this.tabs_item[index].type,
          userId: this["userInfo"].id,
          departmentCode: this["departmentCode"],
        };
        const res = await getTaskInfo(params);
        const list = [...res.records];
        list.forEach((v) => {
          v.worker = Array.from(new Set(v.workers)).join();
        });
        this.taskInfo = list;
      } catch (err) {
        console.log(err);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getUserInfo();
    this.onLoad();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
};
</script>
<style lang='less' scoped>
.production {
  width: 100vw;

  /deep/ .van-dropdown-menu {
    .van-dropdown-menu__bar {
      box-shadow: none;
      height: 0;
    }

    .van-dropdown-item {
      top: 140px !important;
    }
  }

  .van-nav-bar {
    z-index: 100;
  }

  .production_tabs {
    width: 100%;
    position: fixed;
    top: 85px;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 30px;
    background-color: #fff;
    z-index: 1000;
    padding: 20px 0;

    .production_tab {
      display: flex;
      width: 25%;

      .production_tab_item {
        width: 100%;
        text-align: center;
      }

      &:nth-child(3) div {
        border-right: 1px solid #999;
      }

      .active {
        color: blue;
      }
    }

    .production_all {
      width: 25%;
      text-align: center;

      span {
        margin-left: 10px;
      }
    }
  }

  .search {
    width: 100%;
    position: fixed;
    top: 150px;
    left: 0;
  }

  .production_scroll {
    padding-top: 280px;
    width: 100%;

    .production_child_item {
      width: 95%;
      margin: auto;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      margin-top: 30px;

      .production_child_item_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;

        .production_child_item_header_l {
          display: flex;
          justify-content: start;
          align-items: center;
          color: black;
          font-weight: 500;

          .line {
            height: 40px;
            margin: 0 10px;
            border-left: 1px solid #999;
          }

          .name {
            margin-bottom: 5px;
          }
        }

        .state {
          color: #1989fa;

          .not_started {
            color: black;
          }

          .start_end {
            color: red;
          }
        }
      }

      .production_child_item_name {
        margin-top: 20px;
        font-size: 30px;
      }

      .production_child_item_schedule {
        font-size: 30px;
        margin-top: 20px;
      }

      .horizontal_line {
        width: 100%;
        border-top: 1px solid #999;
        margin: 20px 0;
      }

      .production_child_item_distribution {

        .distribution {
          display: flex;
          justify-content: center;

          .distribution_item {
            padding: 5px 10px;
            text-align: center;
            color: blue;
            font-size: 30px;
          }
        }
      }

      .production_tiao {
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .production_tiao_r {
          color: blue;
          border: 1px solid blue;
          border-radius: 10px;
          padding: 10px;
        }
      }
    }
  }

  .jurisdiction {
    padding: 20px;

    .jurisdiction_headle {
      display: flex;
      justify-content: space-between;
      font-size: 30px;
    }

    .van-checkbox-group {
      margin-top: 30px;
      font-size: 30px;

      .van-checkbox {
        margin-top: 15px;
      }
    }
  }
}
</style>
